<template>
  <router-link :to="to" class="room-category-item-wrapper">
    <div class="room-category-item active">
      <div class="room-category-item__image" :style="styleItem(category.image)">
        <ul class="room-category-item__image--list">
          <li
            v-for="(service, serviceIndex) in list"
            :key="`is-${serviceIndex}`"
          >
            {{ service.name }}
          </li>
        </ul>
      </div>
      <div class="room-category-item__content">
        <div class="room-category-item__content--title">
          {{ category.name }}
        </div>
        <div class="room-category-item__content--price">{{ price }}</div>
        <div class="room-category-item__content--ckeckbox">
          <v-icon color="success">mdi-check-circle</v-icon>
        </div>
      </div>
    </div>
    <div class="room-category-item-action">
      <v-btn fab color="primary">
        <IconEdit :size="'28'" color="white" />
      </v-btn>
    </div>
  </router-link>
</template>

<script>
import { pathImage } from "@/utils/index.js";
export default {
  name: "CategoryListOfRoomItemActive",
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
  },
  props: {
    category: {
      type: Object | Number,
      required: true,
    },
  },
  computed: {
    price() {
      let sum = !this.category?.services
        ? 0
        : this.category.services.reduce((prevValue, currentService) => {
            let servicePriceWithTax = currentService?.price_with_tax
              ? currentService.price_with_tax
              : 0;
            let servicePriceDiscount = currentService?.discount_price
              ? currentService?.discount_price
              : servicePriceWithTax;
            return (
              prevValue +
              (servicePriceDiscount +
                (currentService?.productPrice
                  ? currentService.productPrice
                  : 0))
            );
          }, 0);
      return `${sum.toFixed(2)}  €`;
    },
    list() {
      return this.category.services.slice(0, 7);
    },
    styleItem() {
      return (path) => {
        let pathUrl = pathImage(path);
        return pathUrl ? { backgroundImage: `url(${pathUrl})` } : null;
      };
    },
    to() {
      return {
        name: "ServiceCategoryId",
        params: {
          categoryId: this.category.id,
        },
        query: { ...this.$route.query }
      }
    }
  },
};
</script>

<style>
</style>